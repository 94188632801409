.App{
  background-color: transparent;
  padding-left: 2vw;
  width:97vw;
}

.tabella-team, th, td{
  border:2px solid white;
  border-collapse: collapse;
  padding: 5px;
}


.marquee-item-desk {
  display: inline-block !important;
  margin-top:2vh; 
  margin-right: 5vw;
  margin-left: 5vw;
  margin-bottom:2vh;
  width: 80vw;
  height: 50%;
  }

  .marquee-item-mob {
    display: none !important;
    margin-top:2vh; 
    margin-right: 5vw;
    margin-left: 5vw;
    width: 80vw;
    height: 50%;
    }
  

.video-container{
  opacity: 30%;
	position: relative;
	width: 95vw;
	height: 85vh;
	overflow: hidden;
}

.mediaFeed{
  width: 32.5%;
  height: 32.5%;
  padding-top: 10px;
}

.videoHome {
  position: absolute;
  left: -10vh;
  height: 300vh;
  width: 300vw; /* 100 * 16 / 9 */
  min-width: 300vh;
  min-height: 200vw; /* 100 * 9 / 16 */
  z-index: 1;
}

.Message{
  position: absolute;
  margin-left: 43vw;
  margin-top: 43vh;
  font-size: 20px;
  z-index: 2;
}

.logo{
  position: absolute;
  margin-left: 23vw;
  margin-top: 20vh;
  width: 50vw;
  height: 50vh;
  z-index: 2;
}


.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


.inputText {
  display: flex;
  flex-direction: column;
}

input[type=text], input[type=email], input[type=password]{
  padding: 4px 4px 4px 100px;
  width: 100%;
  background-color: black;
  border: 1px solid white;
  color: white;
  font-size:20px;
  margin-top: 12px;
  padding-left:10px;
}

input[type=text]:focus, input[type=email]:focus, input[type=password]:focus{
  font-size:20px;
  font-style: normal;
  border: 1px solid white;
  outline:none;
}

input[type=text]::placeholder, input[type=email]::placeholder, input[type=password]::placeholder {
  color:rgba(255, 255, 255, 0.7);
}

button[type=submit]{
  display:block;
  background-color: white;
  margin-top: 12px;
  color: black;
  font-size: 20px;
  padding:  8px 24px 9px;
}

button[type=submit]:hover{
  color: rgba(0, 0, 0, 0.6);
}

input[type="checkbox"] {
  appearance: none;
  background-color: #000;
  margin-top: 12px;
  width: 2em;
  height: 2em;
  border: 1px solid white;
  display: inline-grid;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"]::before {
  content: url(./assets/circle.svg);
  transform: scale(0);
  transition: 90ms transform ease-in-out;
}

input[type="checkbox"]:checked::before {
  transform: scale(0.7);
}

input[type="checkbox"] + label{
  font-size: 20px;
  margin-left: 12px;
}

.space-between{
  display: flex;
  justify-content: space-between;
}



p,a{
  color: white;
  font-size: 20px;
}

p~form{
  margin-top: 6px;
}



.info-container{
    padding-left: 10%;
    padding-right: 10%;
}



.Text {
  display: flex;
  flex-direction: column;
}

.About{
  width:90%;
}

@media only screen and (max-width: 900px) {
  .mediaFeed{
    width: 100%;
    height: 100%;
    padding-top: 10px;
  }
  

  .marquee-item-desk {
    display: none !important;
    }
  
  
    .marquee-item-mob {
      display: inline-block !important;
    }

  .Message{
    position: absolute;
    margin-left: 40vw;
    margin-top: 40vh;
    font-size: 20px;
    z-index: 2;
  }
  .logo{
    position: absolute;
    margin-left: 13vw;
    margin-top: 10vh;
    width: 70vw;
    height: 70vh;
    z-index: 2;
  }
  .App{
    background-color: transparent;
    padding-left: 2vw;
    width:95vw;
  }

  .video-container{
    width:93vw;
  }

  .marquee-item {
    margin-top:2vh; 
    margin-right: 5vw;
    margin-left: 5vw;
    width: 60vw;
    height: 6vh;
    }
}

@media only screen and (max-width: 700px) {
  
  .Message{
    position: absolute;
    margin-left: 32vw;
    margin-top: 40vh;
    font-size: 20px;
    z-index: 2;
  }

  .video-container{

    width:93vw;
  }
}