@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "neue-haas-grotesk-display", sans-serif;
  font-weight: 400;
  font-style: normal;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
